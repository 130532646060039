<template lang="">
    <div class="card transparent">
        <div class="card-header cart-summary" id="headingOne">
            <div class="row">
                <div class="col-md-12">
                    <button 
                            class="btn btn-outline-default btn-sm " 
                            data-toggle="collapse" 
                            data-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                            v-on:click="scrollToItem(cart_item_data.item_id)">
                            <i class="fa fa-edit"></i>
                        </button>
                    <p class="text-left abw_cart_item_name"><strong >{{ cart_item_data.item_name }}</strong></p>
                    </div>
            </div>
            <div v-if="cart_item_data.item_adults > 0" class="row">
                <div class="col-5">
                    <p class="text-left abw_cart_item_price_label"><small>Adults x {{ cart_item_data.item_adults}}</small></p>
                </div>
                <div class="col-7">
                    <p class="text-right abw_cart_item_base_price"><small>
                        <strong>{{this.$store.getters.getCurrency}}  {{cart_item_data.adult_price[cart_item_data.item_adults] ? 
                            (cart_item_data.item_adults * (cart_item_data.adult_price[cart_item_data.item_adults] / cart_item_data.item_adults)) : 
                            cart_item_data.adult_price[1] }}</strong></small></p>
                </div>
            </div>
            <div v-if="cart_item_data.item_children > 0" class="row">
                <div class="col-5">
                    <p class="text-left abw_cart_item_price_label"><small>Children x {{ cart_item_data.item_children}}</small></p>
                </div>
                <div class="col-7">
                    <p class="text-right abw_cart_item_base_price"><small>
                        <strong>{{this.$store.getters.getCurrency}}  {{cart_item_data.child_price[cart_item_data.item_children] ? 
                            ( cart_item_data.item_children * (cart_item_data.child_price[cart_item_data.item_children] / cart_item_data.item_children )) : 
                            cart_item_data.child_price[1]}}</strong></small></p>
                </div>
            </div>
            <div v-if="cart_item_data.item_infants > 0" class="row">
                <div class="col-5">
                    <p class="text-left abw_cart_item_price_label"><small>Infants x {{ cart_item_data.item_infants}}</small></p>
                </div>
                <div class="col-7">
                    <p class="text-right abw_cart_item_base_price"><small>
                        <strong>{{this.$store.getters.getCurrency}}  {{ cart_item_data.infant_price[cart_item_data.item_infants] ? 
                            (cart_item_data.item_infants * (cart_item_data.infant_price[cart_item_data.item_infants] / cart_item_data.item_infants )) : 
                            cart_item_data.infant_price[1] }}</strong></small></p>
                </div>
            </div>
            
            <div v-if="cart_item_data.enhancements.length > 0" class="row">
                <div class="col-5">
                    <p class="text-left abw_cart_item_extras_label"><small>Extras</small></p>
                </div>
                <div class="col-7">
                    <p class="text-right abw_cart_item_extras_text"><small><strong>{{this.$store.getters.getCurrency}}  {{ enhancementsTotal }}</strong></small></p>
                </div>
            </div>
            <div v-if="cart_item_data.inclusions.length > 0" class="row">
                    <div class="col" >
                        <!-- <h4><span class="text" style="color: #000000;">Extras</span></h4> -->
                        <div class="row ">
                            <div class="col" >
                                <span style="font-size: 14px;text-align: left;"><strong>Includes</strong></span>
                            </div>
                        </div>
                        <div v-for="inclusion of cart_item_data.inclusions " :key="inclusion.id" class="row">
                            <div class="col" >
                                <li style="font-size: 12px;text-align: right;">
                                    {{inclusion.name}} </li>
                            </div>
                        </div>
                       
                    </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <p class="text-left abw_cart_item_subtotal_text" style="color: #ff573d;">
                        <strong><small>{{this.$store.getters.getCurrency}}  {{ subTotal }}</small></strong>
                    </p>
                </div>
                <div class="col-7 d-flex justify-content-end">
                    
                    <button class="btn btn-outline-primary btn-sm abw_cart_item_details_btn" 
                    data-toggle="collapse" :data-target="'#collapse_'+cart_item_data.item_id" aria-expanded="false" aria-controls="collapseOne">
                        Details 
                    </button>
                    &nbsp;
                    <div v-if="item_removing_from_cart">
                        <div class="d-flex justify-content-center">
                            <!-- <p>Removing Item..&nbsp;&nbsp;</p> -->
                            <div class="spinner-border text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <button 
                            class="btn btn-outline-danger btn-sm abw_cart_item_remove_btn" 
                            data-toggle="collapse" 
                            data-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                            v-on:click="removeFromCart">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            
            
                
        </div>
        <div :id="'collapse_'+cart_item_data.item_id" class="collapse abw_cart_item_collapsed" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
                <div v-if="cart_item_data.enhancements.length > 0" class="row">
                    <div class="col" style="background: #ffffff;">
                        <!-- <h4><span class="text" style="color: #000000;">Extras</span></h4> -->
                        <div class="row">
                            <div class="col" style="background: #ffffff;">
                                <p style="background: #fff;font-size: 12px;text-align: left;"><strong>Extras</strong></p>
                            </div>
                        </div>
                        <div v-for="enhancement of cart_item_data.enhancements " :key="enhancement.id" class="row">
                            <div class="col" style="background: #ffffff;">
                                <p style="background: #ffffff;font-size: 12px;text-align: left;">
                                    {{enhancement.name}} x {{enhancement.qty}}</p>
                            </div>
                            <div class="col" style="background: #ffffff;">
                                <p style="background: #ffffff;font-size: 12px;text-align: right;">{{this.$store.getters.getCurrency}} {{enhancement.price}}</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="mgtb-1"><span class="text abw_cart_item_subtotal_text" style="color: #000000;">Sub Total:&nbsp;</span><span class="price" style="color: #ff573d;">{{this.$store.getters.getCurrency}} {{ subTotal }}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
import APIService from "../../services/api_service";
import numeral from "numeral";


export default {
  name: "CartItemType2",
  props: {
    cart_item_data: Object,
  },
  data(){
      return {
          item_removing_from_cart : false
      };
  },
  computed: {
    enhancementsTotal() {
      var total = 0;
      this.cart_item_data.enhancements.forEach((element) => {
          console.log(element);
        total = (parseFloat(element.price) * element.qty) + total;
      });
      console.log("Enhancement "+total);
      return total;
    },
    subTotal() {
      var adult_total =
        this.cart_item_data.item_adults * this.cart_item_data.adult_price[1];
      var child_total =
        this.cart_item_data.item_children * this.cart_item_data.child_price[1];
      var infant_total =
        this.cart_item_data.item_infants * this.cart_item_data.infant_price[1];
      var enhancement_total = this.enhancementsTotal;

      return numeral(adult_total  + child_total + infant_total  + enhancement_total).format("0,0.00");
    },
  },

  methods: {
    removeFromCart() {
      this.item_removing_from_cart = true;

    //   var json_body = {};
    //   json_body = {
    //     cart_id: this.$store.getters.getCart.cart_id,
    //     item_id: this.cart_item_data.item_id,
    //   };
      
      console.log(this.$store.getters.getCart);
      APIService.removeFromCart(this.$store.getters.getCompanyId ,  this.$store.getters.getCart.cart_id , this.cart_item_data.item_id)
        .then((json) => {
          this.$store.commit("setCart", json);
          this.item_removing_from_cart = false;
        })
        .catch((err) => {
          console.log(err);
          return err;
        })
        .then((err_data) => {
          if (err_data != undefined) {
            console.log(err_data.message);
            this.item_error_message = err_data.message;
            this.item_has_error = true;
            this.item_removing_from_cart = false;
          }
        });
    }, 

    scrollToItem(item_id){
      var targetElm = document.getElementById("abw_item_"+item_id);  // reference to scroll target
      targetElm.scrollIntoView({ block: 'center',  behavior: 'smooth' });
    }
  },
};
</script>
<style lang="">
</style>