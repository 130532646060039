
export default {

  data() {
    return {
      // url: "http://localhost/ppal_ab_api/ActivityBookingAPI"
      url: "https://activity.reserveport.com/ab_api"
    }
  },

  getActivitiesAvailableToday(company_id, date) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    };
    return fetch(this.data().url + "/public/index.php/api/booking-engine/"+company_id+"/available/activities/"+date, requestOptions).then((res) => res.json());
  },

  getPartialPaymentRate(company_id) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    };
    return fetch(this.data().url + "/public/index.php/api/config/"+company_id+"/partial-payment-rate", requestOptions).then((res) => res.json());
  },

  getPartialPaymentEnabled(company_id) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    };
    return fetch(this.data().url + "/public/index.php/api/config/"+company_id+"/partial-payment-enabled", requestOptions).then((res) => res.json());
  },

  getPartialPaymentType(company_id) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    };
    return fetch(this.data().url + "/public/index.php/api/config/"+company_id+"/partial-payment-type", requestOptions).then((res) => res.json());
  },
  getConfigurations(company_id) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    };
    return fetch(this.data().url + "/public/index.php/api/config/"+company_id, requestOptions).then((res) => res.json());
  },

  addToCart(company_id ,json_body) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(
        json_body
      )
    }   ;
    return fetch(this.data().url + "/public/index.php/api/booking-engine/"+ company_id +"/carts", requestOptions)
      .then((response) => {
        // console.log(response.json());
        if (response.status != 200) {
          throw response.json();
        } else {
          return response.json();
        }
      });
  },

  checkoutCart(json_body) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(
        json_body
      )
    }   ;
    return fetch(this.data().url + "/public/index.php/api/book_cart", requestOptions)
      .then((response) => {
        // console.log(response.json());
        if (response.status != 200) {
          throw response.json();
        } else {
          return response.json();
        }
      });
  },

  removeFromCart(company_id , cart_id , item_id ) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "Accept": "application/json" }
    }   ;
    return fetch(this.data().url + "/public/index.php/api/booking-engine/"+company_id+"/carts/"+cart_id+"/items/"+item_id, requestOptions)
      .then((response) => {
        // console.log(response.json());
        if (response.status != 200) {
          throw response.json();
        } else {
          return response.json();
        }
      });
  }
}