<template>
<div v-if="show_btn == 'true'">
    <div class="d-flex justify-content-around p-3">
      <button id="booknow_btn" class="btn btn btn-primary btn-sm abw_cart_checkout_btn" type="button" data-toggle="collapse" data-target="#abw_book_now_cb" aria-expanded="false" aria-controls="collapseExample">
        Book Now &nbsp;
        <i class="fa fa-chevron-up pull-right"></i>
        <i class="fa fa-chevron-down pull-right"></i>
      </button>
    </div>
    
    <div class="collapse" id="abw_book_now_cb">
      <div class="container">
        <search-bar v-if="show_date_picker == 'true'" :searchbar_date="this.$store.getters.getSearchDate"></search-bar>
        <main-stepper></main-stepper>
      </div>
    </div>
  </div>
<div v-else>
    <div class="container">
        <search-bar v-if="show_date_picker == 'true'" :searchbar_date="this.$store.getters.getSearchDate"></search-bar>
        <main-stepper></main-stepper>
      </div>
</div>

</template>

<script>

import MainStepper from './components/MainStepper.vue';
import SearchBar from "./components/SearchBar.vue";


export default {
  name: "App",
  data(){
    return {
      show_btn : true,
      show_date_picker : true
    }
  },
  beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  beforeMount() {
      console.log(this.widget_co_id);

      if(document.getElementById('show_btn')){
          this.show_btn = document.getElementById('show_btn').value;
      }else{
          this.show_btn = true;
      }

      if(document.getElementById('show_date_picker')){
          this.show_date_picker = document.getElementById('show_date_picker').value;
      }else{
          this.show_date_picker = true;
      }

  },
  components: {
    MainStepper,
    SearchBar
  },
  
};
</script>

<style>
@import '../public/assets/css/template.css';
@import url(https://use.fontawesome.com/e220fd8e50.css);

button[aria-expanded=true] .fa-chevron-down {
   display: none;
}
button[aria-expanded=false] .fa-chevron-up {
   display: none;
}

</style>
