<template lang="">
    <div  :id="'abw_item_'+item_data.id" class="abw_act_item_card">
        <div class="abw_act_item_card-body">
            <div class="items">
                <div class="product">
                    <div class="justify-content-center align-items-center">
                        <div class="abw_act_item_details">
                            
                                
                                    <div class="row" style="margin-top: 6px;margin-bottom: 6px;">
                                        <div class="col-md-4 abw_act_item_details_image">
                                            <figure>
                                            <img class="img-fluid d-block mx-auto image" :src=" (item_data.photos.length > 0) ? item_data.photos[0].file_location : '' ">
                                        </figure>
                                        <div class="row justify-content-center align-items-center Pbtn">
                                         
                                            <div v-if="(item_data.more_details_url == '')" class="col" style="margin-top: 6px;">
                                                <button class="abw_act_item_details_btn" type="button" data-toggle="modal" :data-target=" itemModalTarget ">More Details
                                                </button>
                                            </div>
                                            <div v-else class="col" style="margin-top: 6px;">
                                                <a class="abw_act_item_details_btn" :href="item_data.more_details_url" type="button" style="font-size: 13px;">View Menu
                                                </a>
                                            </div>
                                            
                                        </div>
                                        </div>
                                        <div class="col-md-8 text-left abw_act_item_details_desc">
                                            <div class="product-name abw_act_item_details_name"><h4 class="itemTitle">{{ item_data.name }}</h4></div>
                                            <div class="itemDesc">{{ itemDescription }}</div>
                                          <div class="choice">
 
                                            <div v-for="pack of item_data.packages" :key="pack.id" class="row" style="margin-top: 4px; margin-bottom: 10px;">
                                                <div class="col align-self-center">
                                                    <div class="row" style="margin-bottom:1rem">
                                                        <div class="col-8 align-self-center" style="text-align: left;">
                                                            <div class="form-check text-left">
                                                                <input class="form-check-input" type="checkbox" :id="'ab_p_cb'+pack.id" v-on:change="changeQty">
                                                                <label class="form-check-label" :id="'ab_p'+pack.id" for="formCheck-9" style="font-size: 14px;">
                                                                    {{ pack.name}} - {{this.$store.getters.getCurrency}} &nbsp;<strong>{{ currency(pack.pricings[0].base_price[1]) }}</strong>
                                                                </label></div>
                                                        </div>
                                                        <div class="col-3 offset-md-1">
                                                        <!-- 
                                                          <input class="form-control-sm form-control quantity-input" 
                                                                type="number" 
                                                                :id="'ab_p_cb_qty_'+pack.id" 
                                                                min="1"
                                                                value= 1
                                                                v-on:change="changeQty"
                                                                style="text-align: center;font-size: 13px; "> 
                                                          -->

                                                                  <div class="qty-input abw_act_item_extra_qty_input" >
                                                                        <button class="qty-count qty-count--minus" data-action="minus" @click="removeQty(pack.id)" type="button">-</button>
                                                                        <input class="product-qty form-control-sm form-control quantity-input" type="number"  :id="'ab_p_cb_qty_'+pack.id" value="1" min="1" style="text-align: center;font-size: 13px;">
                                                                        <button class="qty-count qty-count--add" data-action="add" type="button" @click="addQty(pack.id)">+</button>
                                                                  </div>
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>

                                            <div :hidden="this.no_item_selected"  class="justify-content-center align-items-center">
                                              <div v-if="item_data.inclusions.length > 0" class="align-self-start">
                                                      <br><br>
                                                      <div style="text-align:center; padding-bottom:5px;">
                                                        <span class="abw_act_item_extras_title product-name" >
                                                        Select your preferred Menus </span>
                                                        
                                                      </div>
                                                      <br>
                                                      
                                                      
                                                        <div>
                                                            <div v-for="inclusion of item_data.inclusions" :key="inclusion.id">
                                                                <div class="row">
                                                                    <div class="col-8 abw_act_item_extra_name">
                                                                         <a style="color:black" :href="'http://'+inclusion.details_url ">
                                                                          <strong><p style="font-size: 13px;">{{ inclusion.name }} </p></strong>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-3 offset-md-1 text-center align-self-right">
                                                                      <input class="form-check-input" type="checkbox" :id="'inclusion_checkbox_'+inclusion.id ">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                        <div><span class="value"></span></div>
                                                    </div>
                                                  <!-- Start Of Extras Section -->
                                                  <!-- ----------------------- -->
                                                  <div class="extra">
                                                      <div v-if="item_data.enhancements.length > 5" class="align-self-start">
                                                              <div class="card-body">
                                                                <span class="abw_act_item_extras_title product-name">Extras</span>
                                                                  <div v-for="(enhancement, key, index) in item_data.enhancements" :key="index" v-show="key < 3 ">
                                                                      <div class="row">
                                                                          <div class="col-7 abw_act_item_extra_name">
                                                                              <p style="font-size: 13px;">{{ enhancement.name }} -&nbsp;<strong>{{this.$store.getters.getCurrency}} {{ currency(enhancement.base_price) }}</strong></p>
                                                                          </div>
                                                                          <div class="col-3 offset-md-1 text-center align-self-center">
                                                                              <div class="qty-input abw_act_item_extra_qty_input">
                                                                                  <button class="qty-count qty-count--minus" data-action="minus" @click="removeEnhancementQty(enhancement.id)" type="button">-</button>
                                                                                  <input class="product-qty form-control-sm form-control quantity-input" type="number" :id="'enhancement_'+enhancement.id" min="0" value="0" style="text-align: center;font-size: 13px;">
                                                                                  <button class="qty-count qty-count--add" data-action="add" type="button" @click="addEnhancementQty(enhancement.id)">+</button>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                     
                                                                  </div>
                                                                   <div class="row">
                                                                         <button class="abw_act_item_details_btn" type="button" data-toggle="modal" :data-target="itemModalTarget+'_extras' " style="font-size: 13px;">See More
                                                                        </button>
                                                                      </div>
                                                                  <div><span class="value"></span></div>
                                                              </div>
                                                      </div>
                                                      <div v-if="((item_data.enhancements.length > 0) &&  ((item_data.enhancements.length < 5) || (item_data.enhancements.length == 5)) )" class="align-self-start">
                                                          <span class="abw_act_item_extras_title product-name">Extras</span>
                                                          <div v-for="enhancement of item_data.enhancements" :key="enhancement.id">
                                                              <div class="row">
                                                                  <div class="col-7 abw_act_item_extra_name">
                                                                      <p style="font-size: 13px;">{{ enhancement.name }} -&nbsp;<strong>{{this.$store.getters.getCurrency}} {{ currency(enhancement.base_price) }}</strong></p>
                                                                  </div>
                                                                  <div class="col-3 offset-md-1 text-center align-self-center">
                                                                      <div class="qty-input abw_act_item_extra_qty_input">
                                                                          <button class="qty-count qty-count--minus" data-action="minus" @click="removeEnhancementQty(enhancement.id)" type="button">-</button>
                                                                          <input class="product-qty form-control-sm form-control quantity-input" type="number" :id="'enhancement_'+enhancement.id" min="0" value="0" style="text-align: center;font-size: 13px;">
                                                                          <button class="qty-count qty-count--add" data-action="add" type="button" @click="addEnhancementQty(enhancement.id)">+</button>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div><span class="value"></span></div>
                                                      </div>
                                                  </div>
                                                  <!-- End of Extras Section -->
                                                  <!-- --------------------- -->
                                            </div>
                                                 <div class="priceAddBtn row">
                                        <div class="col-6"><span class="priceItem">{{ company_currency }} {{ currency(this.item_selected_total) }} </span></div>
                                             <div class="col-6">
                          <div v-if="item_adding_to_cart">
                            <div class="d-flex justify-content-center">
                                <p>Adding Item..&nbsp;&nbsp;</p>
                                <div class="spinner-border text-danger" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                          </div>
                          <div v-else>
                                <button class="abw_act_item_cart_btn" type="button" v-on:click="addActivityToCart"
                                :key="addToCartBtnKey"
                                :disabled="no_item_selected">
                                Add To Cart
                                </button>
                          </div>
                        </div>
                        </div><!--priceAddbtn-->
                                        
                                        </div>
                                           
                                </div>
                                
                                
                        </div>
                        
                    </div>
                    <div v-if="item_has_error" class="row justify-content-center align-items-center">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <small><strong>Oops!</strong>&nbsp;  <div id="err_message_text">{{ item_error_message }}</div>.</small>
                            <div v-if="refreshing_session" class="text-center">
                                <small> Starting a new Booking Session..</small><br>
                                <div class="spinner-border text-danger" role="status">
                                        <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>

<!-- --------------------- -->
<!-- Start of Extras Modal -->
<!-- --------------------- -->

<div class="modal fade" :id="itemModalID+'_extras' " tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Extras</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="overflow-y: auto; max-height: 640px">
                <div v-for="enhancement of item_data.enhancements" :key="enhancement.id">
                    <div class="row">
                        <div class="col-7 abw_act_item_extra_name">
                            <p style="font-size: 13px;">{{ enhancement.name }} -&nbsp;<strong>{{this.$store.getters.getCurrency}} {{ currency(enhancement.base_price) }}</strong></p>
                        </div>
                        <div class="col-3 offset-md-1 text-center align-self-center">
                            <div class="qty-input abw_act_item_extra_qty_input">
                                <button class="qty-count qty-count--minus" data-action="minus" @click="removeEnhancementQty(enhancement.id)" type="button">-</button>
                                <input class="product-qty form-control-sm form-control quantity-input" type="number" :id="'modal_enhancement_'+enhancement.id" min="0" value="0" style="text-align: center;font-size: 13px;">
                                <button class="qty-count qty-count--add" data-action="add" type="button" @click="addEnhancementQty(enhancement.id)">+</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <h4><strong>{{this.$store.getters.getCurrency}} {{ currency(enhancements_total) }}</strong></h4>
                </div>
            </div>
            <div class="modal-footer">
      
                <!-- <button type="button" class="btn btn-secondary" >Close</button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- --------------------- -->
<!--  End of Extras Modal  -->
<!-- --------------------- -->

    <div class="modal fade" :id=" itemModalID " tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ item_data.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="overflow-y: auto; max-height: 640px">
                    <div class="row">
                        <div class="col-md-12">
                            <div :id="'carousel'+item_data.id" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div v-for="(photo,index) of item_data.photos" :key="photo.id" :class="'carousel-item '+ (index == 0 ? 'active': '')">
                                        <img class="img-fluid d-block mx-auto image w-100" 
                                        style="object-fit:cover;width:200px;height:300px;" :src=" (photo.file_location.length > 0) ? photo.file_location : '' ">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" :data-target="'#carousel'+item_data.id" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" :data-target="'#carousel'+item_data.id" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="m-4">
                        {{ item_data.desc }}
                    </div>
                    <div class="m-4">
                        <h4>Prices</h4>
                    </div>
                    <div class="m-4">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Package Prices</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <div v-for="pack of item_data.packages" :key="pack.id" class="row" style="margin-top: 4px;margin-bottom: 4px;">
                                            <div class="col align-self-center">
                                                <div class="row">
                                                    <div class="col-12 align-self-center" style="text-align: left;">
                                                        <div class="form-check text-left">
                                                            <input class="form-check-input" type="checkbox" :id="'ab_p_cb'+pack.id" v-on:change="changeQty">
                                                            <label class="form-check-label" :id="'ab_p'+pack.id" for="formCheck-9" style="font-size: 14px;">
                                                                {{ pack.name}} - {{this.$store.getters.getCurrency}} &nbsp;<strong>{{ pack.pricings[0].base_price[1] }}</strong>
                                                            </label></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <tr v-for="pack of item_data.packages" :key="pack.id">
                                            <td>
                                                <li>{{ pack.name}} - {{this.$store.getters.getCurrency}} &nbsp;<strong>{{ currency(pack.pricings[0].base_price[1]) }}</strong></li>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" >Close</button> -->
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import APIService from "../../services/api_service";
import $ from "jquery";
import numeral from "numeral";

export default {
  name: "ItemType6",
  props: {
    item_data: Object,
    company_currency: String
  },
  data() {
    return {
      item_selected_total: 0,
      item_has_error: false,
      item_adding_to_cart: false,
      item_error_message: "",
      refreshing_session: false,
      adult_qty: 0,
      child_qty: 0,
      infant_qty: 0,
      enhancements_total : 0,
      no_item_selected: true,
      addToCartBtnKey : 0
    };
  },
  computed: {
    getPackagesTotal() {
      var packages_total = 0;
      this.item_data.packages.forEach((element) => {
        console.log(
          "Element by ID : " +
            document.getElementById("ab_p_cb" + element.id).checked
        );

        if (document.getElementById("ab_p_cb" + element.id).checked) {
          console.log("ab_p_cb" + element.id);
          packages_total = packages_total + element.pricings[0].base_price[1];
        }
      });
      return packages_total;
    },

    // a computed getter
    itemDescription() {
      // `this` points to the component instance

      if (this.item_data.desc == null) {
        return "";
      }

      if (this.item_data.desc.length > 180) {
        return this.item_data.desc.slice(0, 180) + "...";
      } else {
        return this.item_data.desc;
      }
    },

    itemModalID() {
      // `this` points to the component instance

      return "m" + this.item_data.id + "Modal";
    },
    itemModalTarget() {
      // `this` points to the component instance

      return "#m" + this.item_data.id + "Modal";
    },

    itemBasePrice() {
      var price = this.item_data.pricings[0].base_price[1];
      console.log(this.item_data.pricings[0].base_price[1]);

      return Math.round(price * 100 + Number.EPSILON) / 100;
    },
  },
  methods: {
    currency(value) {
      // console.log(value);
      return numeral(value).format("0,0.00");
    },
    changeQty() {
      var packages_total = 0;
      this.item_data.packages.forEach((element) => {
        if (document.getElementById("ab_p_cb" + element.id).checked) {
            $("#ab_p_cb_qty_" + element.id).prop('disabled',false);
            var qty = document.getElementById("ab_p_cb_qty_" + element.id).value;
            if(element.pricings[0].base_price[qty]){
                packages_total = packages_total +  (element.pricings[0].base_price[qty]);
            }else{
                packages_total = packages_total + (element.pricings[0].base_price[1] * qty);
            }
        }else{
            $("#ab_p_cb_qty_" + element.id).prop('disabled',true);
        }
      });

      var enhancements_total = 0;
      this.item_data.enhancements.forEach((element) => {
        var cost =
          element.base_price *
          document.getElementById("enhancement_" + element.id).value;
        enhancements_total = cost + enhancements_total;
      });

      
      this.enhancements_total = enhancements_total;
      this.item_selected_total = packages_total + enhancements_total;

      if(packages_total == 0){
        this.no_item_selected = true;
      }else{
        this.no_item_selected = false;
      }
      this.addToCartBtnKey++;
    },

    addQty(element_id){
      if (!document.getElementById("ab_p_cb" + element_id).checked) {
        return;
      }
      let value = document.getElementById("ab_p_cb_qty_" + element_id).value;
      value = parseInt(value) + 1;
      document.getElementById("ab_p_cb_qty_" + element_id).value = value;
      this.changeQty();
    },
    removeQty(element_id){
      if (!document.getElementById("ab_p_cb" + element_id).checked) {
        return;
      }
      let value = document.getElementById("ab_p_cb_qty_" + element_id).value;
      if(value > 0){
        value = parseInt(value) - 1;
        document.getElementById("ab_p_cb_qty_" + element_id).value = value;
      }
      this.changeQty();
    },
    addEnhancementQty(enhancement_id){
      let value = document.getElementById("enhancement_" + enhancement_id).value;
      value = parseInt(value) + 1;
      document.getElementById("enhancement_" + enhancement_id).value = value;
      document.getElementById("modal_enhancement_" + enhancement_id).value = value;
      this.changeQty();
    },
    removeEnhancementQty(enhancement_id){
      let value = document.getElementById("enhancement_" + enhancement_id).value;
      if(value > 0){
        value = parseInt(value) - 1;
        document.getElementById("enhancement_" + enhancement_id).value = value;
        document.getElementById("modal_enhancement_" + enhancement_id).value = value;

      }
      this.changeQty();
    },
    addActivityToCart() {
      this.item_adding_to_cart = true;
      this.item_has_error = false;
      console.log("Adding Activity To Cart...");

      var extras = [];
      this.item_data.enhancements.forEach((element) => {
        var enh_qty = document.getElementById(
          "enhancement_" + element.id
        ).value;
        if (enh_qty > 0) {
          var enhancement = {
            id: element.id,
            qty: enh_qty,
          };
          extras.push(enhancement);
        }
      });

      var inclusions = [];
      this.item_data.inclusions.forEach((element) => {
  
        if (document.getElementById("inclusion_checkbox_" + element.id).checked) {
          inclusions.push(element);
        }
      });

      var packages = [];
      this.item_data.packages.forEach((element) => {
           if (document.getElementById("ab_p_cb" + element.id).checked) {
               var qty = document.getElementById("ab_p_cb_qty_" + element.id).value;
                 var pack = {
                        id: element.id,
                        qty
                    };
                    packages.push(pack);
            }
        
        
      });

      var json_body = {};
      if (Object.keys(this.$store.getters.getCart).length == 0) {
        json_body = {
          item: {
            activity_id: this.item_data.id,
            date: this.$store.getters.getSearchDate,
            packages: packages,
            extras: extras,
            inclusions: inclusions,
          },
        };
      } else {
        json_body = {
          cart_id: this.$store.getters.getCart.cart_id,
          item: {
            activity_id: this.item_data.id,
            date: this.$store.getters.getSearchDate,
            packages: packages,
            extras: extras,
            inclusions: inclusions,
          },
        };
      }

      //   var today = new Date();

      //     var date = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      //     var date_str = date.toISOString().split('T')[0];
      console.log(this.$store.getters.getCart);
      APIService.addToCart(this.$store.getters.getCompanyId , json_body)
        .then((json) => {
          // console.log("Then : ",json);
          // this.$store.state.setCart(json);
          this.$store.commit("setCart", json);

          this.item_adding_to_cart = false;
        })
        .catch((err) => {
          console.log(err);
          return err;
        })
        .then((err_data) => {
          if (err_data != undefined) {
            console.log(err_data.message);
            if(err_data.message == "This Cart Has Expired"){
              this.item_error_message = "This Booking Session Has Expired. ";
              this.refreshing_session = true;
              setTimeout(function(){
                localStorage.removeItem("ab_w_vxstore");
                location.reload();
              }, 5000);
              this.item_has_error = true;
              this.item_adding_to_cart = false;
              return;
            }
            this.item_error_message = err_data.message;
            if(err_data.reason=="ongoing_booking"){
              var countDownDate = err_data.expiry_time;
              console.log("Expiry Time : "+ err_data.expiry_time);
              var distance = countDownDate;

              var x = setInterval(function() {
                  console.log("settimeout : " + distance);
                // Get today's date and time
                // var now = new Date().getTime();

                // Find the distance between now and the count down date
                distance = distance - 1;

                // Time calculations for days, hours, minutes and seconds
                // var days = Math.floor(0 / (1000 * 60 * 60 * 24));
                // var hours = Math.floor((0 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                // var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="demo"
                document.getElementById("err_message_text").innerHTML = err_data.message +"<br>"+((new Date(distance * 1000).getMinutes() > 0) ? new Date(distance * 1000).getMinutes()+" Minutes and " : "")+new Date(distance * 1000).getSeconds() +" Seconds left.";
                // this.item_error_message = err_data.message +"<br>"+ minutes + "m " + seconds + "s ";

                // If the count down is finished, write some text
                if (distance < 0) {
                  clearInterval(x);
                  this.item_has_error = false;
                }

              }, 1000);
            }

            this.item_has_error = true;
            this.item_adding_to_cart = false;
          }
        });
    },
  },
};
</script>
<style lang="">
</style>