
import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            search_date : "",
            header_text : "",
            header_subtext : "",
            payment_link : "",
            payment_iframe : "",
            currency:"",
            quantifier_text:"",
            special_requirements_text: "",
            partial_payments_rate : 0,
            partial_payments_enabled : false,
            partial_payments_amount : 0,
            partial_payments_type : 1,
            partial_payments_choice : "full" ,
            display_mobile_view_cart : false,
            stepper: 0,
            company_id : 4,
            cart: {
                cart_total: 0,
                cart_items: []
            }
        }
    },
    mutations: {
        initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('ab_w_vxstore')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('ab_w_vxstore')))
				);
			}
		},
        setCart(state, new_cart) {
            state.cart = new_cart;
        },
        clearCart(state) {
            state.cart =  {
                cart_total: 0,
                cart_items: []
            };
        },
        setSearchDate(state , search_date){
            state.search_date = search_date;
        },
        setPaymentLink(state , payment_link){
            state.payment_link = payment_link;
        },
        setPaymentIframe(state , payment_link){
            state.payment_iframe = payment_link;
        },
        setCurrency(state , currency){
            state.currency = currency;
        },
        setPartialPaymentsRate(state , rate){
            state.partial_payments_rate = rate;
        },
        setPartialPaymentsEnabled(state , enabled){
            state.partial_payments_enabled = enabled;
        },
        setPartialPaymentsAmount(state , amount){
            state.partial_payments_amount = amount;
        },
        setPartialPaymentsType(state , type){
            state.partial_payments_type = type;
        },
        setPartialPaymentsType1Note(state , note) {
            state.partial_payments_type_1_note = note;
        },
        setPartialPaymentsType2Note(state , note) {
            state.partial_payments_type_2_note = note;
        },
        setPartialPaymentsChoice(state , choice){
            state.partial_payments_choice = choice;
        },
        setQuantifierText(state , text) {
            state.quantifier_text = text;
        },
        setSpecialRequirementsText(state , text){
            state.special_requirements_text = text;
        },
        setStepper(state, stepper) {
            state.stepper = stepper;
        },
        setDisplayMobileViewCart(state , display){
            state.display_mobile_view_cart = display;
        },
        setCompanyId(state , company_id){
            state.company_id = company_id;
        },
        setHeaderText(state , header_text){
            state.header_text = header_text;
        },
        setHeaderSubtext(state , header_subtext){
            state.header_subtext = header_subtext;
        },
        checkoutCart(){
        }
    },
    getters: {
        getPaymentIframe(state){
            return state.payment_iframe ;
        },
        getCart(state) {
            return state.cart;
        },
        getSearchDate(state) {
            return state.search_date;
        },
        getPaymentLink(state) {
            return state.payment_link;
        },
        getCurrency(state) {
            return state.currency;
        },
        getPartialPaymentsRate(state) {
            return state.partial_payments_rate;
        },
        getPartialPaymentsEnabled(state) {
            return state.partial_payments_enabled;
        },
        getPartialPaymentsType(state) {
            return state.partial_payments_type;
        },
        getPartialPaymentsType1Note(state) {
            return state.partial_payments_type_1_note;
        },
        getPartialPaymentsType2Note(state) {
            return state.partial_payments_type_2_note;
        },
        getPartialPaymentsAmount(state) {
            return state.partial_payments_amount;
        },
        getPartialPaymentsChoice(state) {
            return state.partial_payments_choice;
        },
        getQuantifierText(state) {
            return state.quantifier_text ;
        },
        getSpecialRequirementsText(state ){
            return state.special_requirements_text;
        },
        getStepper(state) {
            return state.stepper;
        },
        getDisplayMobileViewCart(state){
            return state.display_mobile_view_cart ;
        },
        getCompanyId(state){
            return state.company_id;
        },
        getHeaderText(state){
            return state.header_text;
        },
        getHeaderSubtext(state){
            return state.header_subtext;
        },
    }
})